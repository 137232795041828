<template>
  <c-box>
    <template v-for="(step, index) in steps">
      <CardStep
        v-if="stepIndex === index && !isSuccess"
        :key="index"
        :current="index + 1"
        :total="steps.length"
        :is-prev="index !== 0"
        :is-next="true"
        @prev="stepIndex--"
        @next="nextForm"
      >
        <c-text font-weight="700" mb="42px"> {{ step.title }} </c-text>
        <c-box v-if="step.type === 'nutritionAssessment'">
          <c-form-control mb="18px">
            <c-form-label
              fontSize="14px"
              color="#555555"
              fontWeigh="400"
              fontFamily="Roboto"
            >
              Berat Badan/Weight (kg)
            </c-form-label>
            <c-input-group size="md">
              <c-input
                type="number"
                min="0"
                placeholder="Masukkan Berat Badan/Weight (kg)"
                height="62px"
                v-model="nutritionAssessment.weight"
              />
            </c-input-group>
          </c-form-control>
          <c-form-control mb="18px">
            <c-form-label
              fontSize="14px"
              color="#555555"
              fontWeigh="400"
              fontFamily="Roboto"
            >
              Tinggi Badan/Height (cm)
            </c-form-label>
            <c-input-group size="md">
              <c-input
                type="number"
                min="0"
                placeholder="Masukkan Tinggi Badan/Height (cm)"
                height="62px"
                v-model="nutritionAssessment.height"
              />
            </c-input-group>
          </c-form-control>
          <c-form-control mb="18px">
            <c-form-label
              fontSize="14px"
              color="#555555"
              fontWeigh="400"
              fontFamily="Roboto"
            >
              IMT/BMI (kg/m2):
            </c-form-label>
            <c-input-group size="md">
              <c-input
                type="number"
                min="0"
                placeholder="Masukkan IMT/BMI (kg/m2)"
                height="62px"
                v-model="nutritionAssessment.bodyMassIndex"
              />
            </c-input-group>
          </c-form-control>
          <c-form-control mb="18px">
            <c-form-label
              fontSize="14px"
              color="#555555"
              fontWeigh="400"
              fontFamily="Roboto"
            >
              Status Gizi
            </c-form-label>
            <c-input-group size="md">
              <c-input
                type="text"
                placeholder="Masukkan Status Gizi"
                height="62px"
                v-model="nutritionAssessment.nutritionalStatus"
              />
            </c-input-group>
          </c-form-control>
          <c-form-control mb="18px">
            <c-form-label
              fontSize="14px"
              color="#555555"
              fontWeigh="400"
              fontFamily="Roboto"
            >
              Kebutuhan Energi Bassal (kkal)
            </c-form-label>
            <c-input-group size="md">
              <c-input
                type="number"
                min="0"
                placeholder="Masukkan Kebutuhan Energi Bassal (kkal)"
                height="62px"
                v-model="nutritionAssessment.basalEnergyNeeds"
              />
            </c-input-group>
          </c-form-control>
          <c-form-control mb="18px">
            <c-form-label
              fontSize="14px"
              color="#555555"
              fontWeigh="400"
              fontFamily="Roboto"
            >
              Kondisi Keibuan
            </c-form-label>
            <c-input-group size="md">
              <c-select
                v-model="nutritionAssessment.maternalCondition"
                placeholder="Kondisi Keibuan"
                height="62px"
              >
                <option value="Tidak ada">Tidak ada</option>
                <option value="Hamil Trimester 1">Hamil Trimester 1</option>
                <option value="Hamil Trimester 2">Hamil Trimester 2</option>
                <option value="Hamil Trimester 3">Hamil Trimester 3</option>
                <option value="Menyusui Bayi 0-6 Bulan">
                  Menyusui Bayi 0-6 Bulan
                </option>
                <option value="Menyusui Bayi 7-12 Bulan">
                  Menyusui Bayi 7-12 Bulan
                </option>
              </c-select>
            </c-input-group>
          </c-form-control>
          <c-form-control mb="18px">
            <c-form-label
              fontSize="14px"
              color="#555555"
              fontWeigh="400"
              fontFamily="Roboto"
            >
              Tambahan kalori kondisi keibuan
            </c-form-label>
            <c-input-group size="md">
              <c-input
                type="number"
                min="0"
                placeholder="Masukkan Tambahan kalori kondisi keibuan"
                height="62px"
                v-model="nutritionAssessment.maternalCalorieAddition"
              />
            </c-input-group>
          </c-form-control>
          <c-form-control mb="18px">
            <c-form-label
              fontSize="14px"
              color="#555555"
              fontWeigh="400"
              fontFamily="Roboto"
            >
              Pertahankan Hal Ini / What to Maintain
            </c-form-label>
            <c-input-group size="md">
              <c-input
                type="text"
                placeholder="Masukkan Pertahankan Hal Ini / What to Maintain"
                height="62px"
                v-model="nutritionAssessment.maintain"
              />
            </c-input-group>
          </c-form-control>
          <c-form-control mb="18px">
            <c-form-label
              fontSize="14px"
              color="#555555"
              fontWeigh="400"
              fontFamily="Roboto"
            >
              Perbaiki Hal InI / What to Improve
            </c-form-label>
            <c-input-group size="md">
              <c-input
                type="text"
                placeholder="Masukkan Perbaiki Hal InI / What to Improve"
                height="62px"
                v-model="nutritionAssessment.improve"
              />
            </c-input-group>
          </c-form-control>
        </c-box>

        <c-box v-if="step.type === 'physicalActivityHistory'">
          <c-form-control mb="18px">
            <c-form-label
              fontSize="14px"
              color="#555555"
              fontWeigh="400"
              fontFamily="Roboto"
            >
              Jenis olahraga & durasi/Exercise type & duration
            </c-form-label>
            <textarea
              rows="8"
              placeholder="Masukkan Jenis olahraga & durasi/Exercise type & duration"
              v-chakra="{
                border: '1px solid #C4C4C4',
                padding: '18px',
                width: '100%',
                borderRadius: '6px',
                height: '180px',
                '&:focus, &:focus-visible': {
                  outline: 'unset',
                },
              }"
              v-model="physicalActivityHistory.excercise"
            ></textarea>
          </c-form-control>
          <c-form-control mb="18px">
            <c-form-label
              fontSize="14px"
              color="#555555"
              fontWeigh="400"
              fontFamily="Roboto"
            >
              Status Kecukupan Aktivitas Fisik
            </c-form-label>
            <c-input-group size="md">
              <c-select
                v-model="physicalActivityHistory.activityStatus"
                placeholder="Masukan Status Kecukupan Aktivitas Fisik"
                height="62px"
              >
                <option value="Kurang Aktif">Kurang Aktif</option>
                <option value="Aktif Level Rendah">Aktif Level Rendah</option>
                <option value="Aktif Level Sedang">Aktif Level Sedang</option>
                <option value="Aktif Level Tinggi">Aktif Level Tinggi</option>
              </c-select>
            </c-input-group>
          </c-form-control>
          <c-form-control mb="18px">
            <c-form-label
              fontSize="14px"
              color="#555555"
              fontWeigh="400"
              fontFamily="Roboto"
            >
              Presentase Aktivitas Fisik
            </c-form-label>
            <c-input-group size="md">
              <c-select
                v-model="physicalActivityHistory.activityPercentage"
                placeholder="Masukan Presentase Aktivitas Fisik"
                height="62px"
              >
                <option value="0">0%</option>
                <option value="10">10%</option>
                <option value="30">30%</option>
                <option value="40">40%</option>
                <option value="50">50%</option>
                <option value="60">60%</option>
                <option value="70">70%</option>
                <option value="80">80%</option>
                <option value="90">90%</option>
              </c-select>
            </c-input-group>
          </c-form-control>
        </c-box>

        <c-box v-if="step.type === 'calorieMacroNutrientIntake'">
            <EnergiForm
                v-if="calorieMacroNutrientIntake"
                v-model="calorieMacroNutrientIntake"
                without-adds
                :aktivitas-fisik="profileGizi.physicalActivity | parseFloat"
                :defisit-kalori="profileGizi.calorieDeficit | parseFloat"
                :kebutuhan-energi="profileGizi.basalEnergyNeeds | parseFloat"
                :tambahan-kalori="profileGizi.extraMaternalCalories | parseFloat"
            />

            <ProteinForm
                v-if="calorieMacroNutrientIntake"
                v-model="calorieMacroNutrientIntake"
                without-adds
                :kebutuhan-asupan-energi="calorieMacroNutrientIntake.energyIntakeNeeds | parseFloat"
                :weight="profileGizi.weight | parseFloat"
            />
            <LemakForm
                v-if="calorieMacroNutrientIntake"
                :value="calorieMacroNutrientIntake"
                without-adds
                :kebutuhan-asupan-energi="calorieMacroNutrientIntake.energyNeeds | parseFloat"
            />
            <KarbohidratForm
                v-if="calorieMacroNutrientIntake"
                :value="calorieMacroNutrientIntake"
                without-adds
                :kebutuhan-asupan-energi="calorieMacroNutrientIntake.energyNeeds | parseFloat"
                :kebutuhan-lemak="calorieMacroNutrientIntake.fatNeedsConstant | parseFloat"
                :kebutuhan-protein="calorieMacroNutrientIntake.proteinNeedsConstant | parseFloat"
            />
            <SeratForm
                v-if="calorieMacroNutrientIntake"
                :value="calorieMacroNutrientIntake"
                without-adds
            />
        </c-box>

        <c-box v-if="step.type === 'otherNote'">
          <c-form-control mb="18px">
            <c-form-label
              fontSize="14px"
              color="#555555"
              fontWeigh="400"
              fontFamily="Roboto"
            >
              Catatan Lainnya dari Hasil Follow Up
            </c-form-label>
            <textarea
              rows="8"
              placeholder="Masukkan Catatan Lainnya dari Hasil Follow Up"
              v-chakra="{
                border: '1px solid #C4C4C4',
                padding: '18px',
                width: '100%',
                borderRadius: '6px',
                height: '180px',
                '&:focus, &:focus-visible': {
                  outline: 'unset',
                },
              }"
              v-model="otherNote"
            ></textarea>
          </c-form-control>
        </c-box>

        <c-box v-if="step.type === 'primaryNutritionDiagnosis'">
          <c-form-control mb="18px">
            <c-form-label
              fontSize="14px"
              color="#555555"
              fontWeigh="400"
              fontFamily="Roboto"
            >
              Problem
            </c-form-label>
            <c-input-group size="md">
              <c-select
                v-model="primaryNutritionDiagnosis.problem"
                placeholder="Masukan Kebutuhan Lemak / Fat (gram)"
                height="62px"
              >
                <option value="Masih Sama Dengan Sebelumnya">
                  Masih Sama Dengan Sebelumnya
                </option>
                <option value="Buat Data Baru">Buat Data Baru</option>
              </c-select>
            </c-input-group>
          </c-form-control>
          <c-form-control mb="18px">
            <c-form-label
              fontSize="14px"
              color="#555555"
              fontWeigh="400"
              fontFamily="Roboto"
            >
              Etiology
            </c-form-label>
            <c-input-group size="md">
              <c-select
                v-model="primaryNutritionDiagnosis.etiology"
                placeholder="Masukan Kebutuhan Lemak / Fat (gram)"
                height="62px"
              >
                <option value="Masih Sama Dengan Sebelumnya">
                  Masih Sama Dengan Sebelumnya
                </option>
                <option value="Buat Data Baru">Buat Data Baru</option>
              </c-select>
            </c-input-group>
          </c-form-control>
          <c-form-control mb="18px">
            <c-form-label
              fontSize="14px"
              color="#555555"
              fontWeigh="400"
              fontFamily="Roboto"
            >
              Sign & Symptoms
            </c-form-label>
            <c-input-group size="md">
              <c-select
                v-model="primaryNutritionDiagnosis.signSymptom"
                placeholder="Masukan Kebutuhan Lemak / Fat (gram)"
                height="62px"
              >
                <option value="Masih Sama Dengan Sebelumnya">
                  Masih Sama Dengan Sebelumnya
                </option>
                <option value="Buat Data Baru">Buat Data Baru</option>
              </c-select>
            </c-input-group>
          </c-form-control>
        </c-box>

        <c-box v-if="step.type === 'nutritionIntervention'">
          <c-form-control mb="18px">
            <c-form-label
              fontSize="14px"
              color="#555555"
              fontWeigh="400"
              fontFamily="Roboto"
            >
              Preskripsi Diet/Nutrition Prescription
            </c-form-label>
            <c-input-group size="md">
              <c-select
                v-model="nutritionIntervention.nutritionPrescription"
                placeholder="Masukan Preskripsi Diet/Nutrition Prescription"
                height="62px"
              >
                <option value="Masih Sama Dengan Sebelumnya">
                  Masih Sama Dengan Sebelumnya
                </option>
                <option value="Buat Data Baru">Buat Data Baru</option>
              </c-select>
            </c-input-group>
          </c-form-control>
          <c-form-control mb="18px">
            <c-form-label
              fontSize="14px"
              color="#555555"
              fontWeigh="400"
              fontFamily="Roboto"
            >
              Target Pencapaian/Goals (SMART)
            </c-form-label>
            <c-input-group size="md">
              <c-select
                v-model="nutritionIntervention.goal"
                placeholder="Masukan Target Pencapaian/Goals (SMART)"
                height="62px"
              >
                <option value="Masih Sama Dengan Sebelumnya">
                  Masih Sama Dengan Sebelumnya
                </option>
                <option value="Buat Data Baru">Buat Data Baru</option>
              </c-select>
            </c-input-group>
          </c-form-control>
        </c-box>

        <c-box v-if="step.type === 'nutritionMonitoring'">
          <c-checkbox-group
            variant-color="green"
            :default-value="nutritionMonitoring.checkParts"
            v-model="nutritionMonitoring.checkParts"
          >
            <c-checkbox
              v-for="(item, index) in checkParts"
              :key="index"
              w="100%"
              mb="16px"
              font-size="18px"
              line-height="27px"
              variant-color="brand"
              :value="item"
            >
              {{ item }}
            </c-checkbox>
          </c-checkbox-group>

          <c-form-control mb="18px">
            <c-form-label
              fontSize="16px"
              fontWeigh="400"
              fontFamily="Roboto"
              font-weight="500"
              font-size="16px"
              line-height="24px"
              color="brand.900"
            >
              Tuliskan indikator spesifik yang akan dimonitor & kriterianya
            </c-form-label>
          </c-form-control>

          <table v-chakra w="100%">
            <thead
              v-chakra
              bg="rgba(0, 140, 129, 0.1);"
              color="brand.900"
              text-align="left"
              text-transform="uppercase"
              font-weight="700"
              font-size="12px"
            >
              <tr>
                <th
                  v-chakra
                  px="20px"
                  py="7px"
                  border-top-left-radius="12px"
                  border-bottom="1px solid #008C81"
                >
                  Jenis Indikator
                </th>
                <th
                  v-chakra
                  px="20px"
                  py="7px"
                  border="1px solid #008C81"
                  border-top="0"
                >
                  Kriteria Indikator
                </th>
                <th
                  v-chakra
                  px="20px"
                  py="7px"
                  border-top-right-radius="12px"
                  border-bottom="1px solid #008C81"
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in nutritionMonitoring.indicators"
                :key="'indicators' + index"
              >
                <td v-chakra border="1px solid #C4C4C4">
                  <c-input
                    type="text"
                    border="unset"
                    placeholder="Masukkan Jenis Indikator"
                    height="62px"
                    v-model="item.type"
                  />
                </td>
                <td v-chakra border="1px solid #C4C4C4">
                  <c-input
                    type="text"
                    border="unset"
                    placeholder="Masukkan Kriteria Indikator"
                    height="62px"
                    v-model="item.criteria"
                  />
                </td>
                <td v-chakra border="1px solid #C4C4C4" align="center">
                  <c-button
                    min-width="auto"
                    variant-color="red"
                    variant="link"
                    @click="nutritionMonitoring.indicators.splice(index, 1)"
                  >
                    <c-image
                      :src="require('@/assets/icon-trash-line.svg')"
                      alt="empty"
                    />
                  </c-button>
                </td>
              </tr>
            </tbody>
          </table>
          <c-button
            variant-color="brand"
            variant="outline"
            border-radius="0 0 12px 12px"
            h="62px"
            w="100%"
            left-icon="add"
            mb="25px"
            @click="addNutritionMonitoringIndicator"
          >
            Tambah Indikator
          </c-button>
        </c-box>
      </CardStep>
    </template>
    <c-flex v-if="isSuccess" flex-dir="column" align="center">
      <c-heading
        as="h2"
        fontSize="36px"
        fontWeight="600"
        color="black.900"
        marginBottom="54px"
        textAlign="center"
        lineHeight="54px"
      >
        Submit Berhasil!
      </c-heading>
      <c-image
        :src="require('@/assets/paid.png')"
        mx="auto"
        marginBottom="30px"
      />
      <c-text
        marginBottom="20px"
        color="gray.900"
        :fontSize="['14px', '24px']"
        :lineHeight="['21px', '36px']"
        fontFamily="Roboto"
        v-text="'Follow Up Notes kamu berhasil di-submit'"
      >
      </c-text>
      <c-button
        as="router-link"
        :to="{ name: 'nutri.follow-up-notes' }"
        fontWeight="700"
        right-icon="arrow-forward"
        color="brand.900"
        variant="outline"
        borderColor="brand.900"
        borderRadius="200px"
        py="10px"
        px="30px"
      >
        Pilih Ahli Gizi
      </c-button>
    </c-flex>
  </c-box>
</template>

<script>
import CardStep from "@/views/nutritionists/follow-up-notes/card-step"
import _ from "lodash"
import {mapActions, mapGetters, mapState} from "vuex"
import StepData from "../steps.js"
import EnergiForm from "@/views/meal-plan/forms/asupan-gizi-form/energi-form";
import ProteinForm from "@/views/meal-plan/forms/asupan-gizi-form/protein-form";
import LemakForm from "@/views/meal-plan/forms/asupan-gizi-form/lemak-form.vue";
import KarbohidratForm from "@/views/meal-plan/forms/asupan-gizi-form/karbohidrat-form";
import SeratForm from "@/views/meal-plan/forms/asupan-gizi-form/serat-form";

export default {
  name: "FollowUpNoteForm",
  components: {ProteinForm, EnergiForm, CardStep, LemakForm, KarbohidratForm, SeratForm },
  data() {
    return {
      // stepIndex: 0,
      steps: StepData.steps,

      isDraft: true,
      dateCounter: null,
      nutritionAssessment: {
        weight: 0,
        height: 0,
        bodyMassIndex: 0,
        nutritionalStatus: null,
        basalEnergyNeeds: 0,
        maternalCondition: "0",
        maternalCalorieAddition: 0,
        maintain: null,
        improve: null,
      },
      physicalActivityHistory: null,
      calorieMacroNutrientIntake: null,
      otherNote: null,
      primaryNutritionDiagnosis: null,
      nutritionIntervention: null,
      nutritionMonitoring: {
        checkParts: [],
        indicators: [],
      },
      checkParts: [
        "Asupan Zat Gizi",
        "Antropometri",
        "Durasi & Intensitas Olahraga",
        "Perilaku & Pengetahuan",
        "Komposisi Tubuh",
        "Hasil Lab",
      ],

      client: {
        gender: "female",
        age: 20,
      },
      calorieDeficit: 0,
      isSuccess: false,
    }
  },
  computed: {
    ...mapState({
      item: (s) => s.nutriFollowUpNotes.followupNote,
    }),
    ...mapGetters({
      profileGizi: "mealPlan/profileGizi",
    }),
    id() {
      return this.$route.query?.id
    },
    stepIndex: {
      get() {
        return parseInt(this.$route.query.stepIndex ?? 0)
      },
      set(v) {
        this.$router.push({
          query: {...this.$route.query, stepIndex: v}
        })
      }
    },
  },
  async mounted() {
    await this.$store.dispatch("mealPlan/getProfileGizi", this.clientId);
  },
  watch: {
    item: {
      immediate: true,
      handler(val) {
        if (val) {
          this.isDraft = val.isDraft
          this.dateCounter = val.dateCounter

          if (_.isEmpty(val.nutritionAssessment)) {
            this.nutritionAssessment = {
              weight: 0,
              height: 0,
              bodyMassIndex: 0,
              nutritionalStatus: 0,
              basalEnergyNeeds: 0,
              maternalCondition: "0",
              maternalCalorieAddition: 0,
              maintain: null,
              improve: null,
            }
          } else {
            this.nutritionAssessment = val.nutritionAssessment
          }

          if (_.isEmpty(val.physicalActivityHistory)) {
            this.physicalActivityHistory = {
              excercise: null,
              activityStatus: null,
              activityPercentage: null,
            }
          } else {
            this.physicalActivityHistory = val.physicalActivityHistory
          }

          if (_.isEmpty(val.calorieMacroNutrientIntake)) {
            this.calorieMacroNutrientIntake = {
              energyIntake: 0,
              energyNeeds: 0,
              energyAdequancy: 0,
              currentProteinIntake: 0,
              proteinNeeds: "",
              proteinIntakeNeeds: 0,
              proteinNeedsConstant: 0,
              proteinAdequancy: 0,
              currentFatIntake: 0,
              fatNeedsConstant: null,
              fatIntakeNeeds: 0,
              fatAdequancy: 0,
              currentCarbohydrateIntake: 0,
              carbohydrateIntakeNeeds: 0,
              carbohydrateAdequancy: 0,
              fiberNeeds: null,
              fiberAdequancy: 0,
            }
          } else {
            this.calorieMacroNutrientIntake = val.calorieMacroNutrientIntake
          }

          this.otherNote = val.otherNote

          if (_.isEmpty(val.primaryNutritionDiagnosis)) {
            this.primaryNutritionDiagnosis = {
              problem: null,
              etiology: null,
              signSymptom: null,
            }
          } else {
            this.primaryNutritionDiagnosis = val.primaryNutritionDiagnosis
          }

          if (_.isEmpty(val.nutritionIntervention)) {
            this.nutritionIntervention = {
              nutritionPrescription: null,
              goal: null,
            }
          } else {
            this.nutritionIntervention = val.nutritionIntervention
          }

          if (_.isEmpty(val.nutritionMonitoring)) {
            this.nutritionMonitoring = {
              checkParts: [],
              indicators: [],
            }
          } else {
            this.nutritionMonitoring = val.nutritionMonitoring
          }
          if (_.isEmpty(val.client)) {
            this.client = {
              gender: "male",
              age: 17,
            }
          } else {
            this.client = val.client
          }
          if (!_.isEmpty(val.mealPlan) && !_.isEmpty(val.mealPlan.calorieDeficit)) {
            this.calorieDeficit = val.mealPlan.calorieDeficit
          }

          if (this.isDraft) {
            if (_.isEmpty(this.nutritionAssessment.maintain)) {
              this.nutritionAssessment.maintain =
                val.adimeNote.nutritionAssessment.maintain
            }

            if (_.isEmpty(this.nutritionAssessment.improve)) {
              this.nutritionAssessment.improve =
                val.adimeNote.nutritionAssessment.improve
            }
            if (_.isEmpty(this.calorieMacroNutrientIntake.carbohydrateNeeds)) {
              this.calorieMacroNutrientIntake.carbohydrateNeeds =
                val.adimeNote.calorieMacroNutrientIntake.carbohydrateNeeds
            }
            if (_.isEmpty(this.calorieMacroNutrientIntake.energyNeeds)) {
              this.calorieMacroNutrientIntake.energyNeeds =
                val.adimeNote.calorieMacroNutrientIntake.energyNeeds
            }
            if (_.isEmpty(this.calorieMacroNutrientIntake.fatNeeds)) {
              this.calorieMacroNutrientIntake.fatNeeds =
                val.adimeNote.calorieMacroNutrientIntake.fatNeeds
            }
            if (_.isEmpty(this.calorieMacroNutrientIntake.proteinAdequancy)) {
              this.calorieMacroNutrientIntake.proteinAdequancy =
                val.adimeNote.calorieMacroNutrientIntake.proteinAdequancy
            }
            if (_.isEmpty(this.calorieMacroNutrientIntake.proteinNeeds)) {
              this.calorieMacroNutrientIntake.proteinNeeds =
                val.adimeNote.calorieMacroNutrientIntake.proteinNeeds
            }
          }
        }
      },
    },
    id: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getFollowUpNotesById(val)
        }
      },
    },
  },
  methods: {
    ...mapActions({
      setFollowUpNotes: "nutriFollowUpNotes/setFollowUpNotesByClientId",
      getFollowUpNotesById: "nutriFollowUpNotes/getFollowUpNotesById",
    }),
    changeTValue(value) {
      console.log('@change', value)
    },
    nextForm() {
      let params = {
        isDraft: this.isDraft,
        dateCounter: new Date(),
        nutritionAssessment: this.nutritionAssessment,
        physicalActivityHistory: this.physicalActivityHistory,
        calorieMacroNutrientIntake: this.calorieMacroNutrientIntake,
        otherNote: this.otherNote,
        primaryNutritionDiagnosis: this.primaryNutritionDiagnosis,
        nutritionIntervention: this.nutritionIntervention,
        nutritionMonitoring: this.nutritionMonitoring,
      }
      if (this.stepIndex < this.steps.length - 1) {
        params.isDraft = true
        this.setFollowUpNotes({
          clientId: this.clientId,
          params,
        })
          .then(() => {
            this.stepIndex++
          })
          .catch((err) => {
            return this.$toast({
              title: "Failed",
              description: err.data.message,
              status: "error",
              duration: 5000,
              position: "bottom-right",
              variant: "subtle",
            })
          })
      } else {
        params.isDraft = false
        this.setFollowUpNotes({
          clientId: this.clientId,
          params,
        })
          .then(() => {
            this.isSuccess = true
          })
          .catch((err) => {
            return this.$toast({
              title: "Failed",
              description: err.data.message,
              status: "error",
              duration: 5000,
              position: "bottom-right",
              variant: "subtle",
            })
          })
      }
    },
    addNutritionMonitoringIndicator() {
      this.nutritionMonitoring.indicators.push({
        type: "",
        criteria: "",
      })
    },
  },
}
</script>
